
import Vue from "vue"
const SearchResponseInfo = () => import("@/components/account/SearchResponseInfo.vue")
import { format } from "date-fns"
import SubHeader from "../global/SubHeader.vue"
import { FindPersonAccountRequest } from "@/types/requests"
import { AccountItem } from "@/types/responses"
import { RadioSearchValues } from "@/types/enums"
export default Vue.extend({
	components: { SubHeader, SearchResponseInfo },
	name: "RadioSearchDb",

    data(): {
		search: string
        responseType: string
        incorrectInput: boolean
        searching: boolean
        radioSearch: string
        query: string
        timestamp: string
        responseStatus: string
        searchType: string
        searchResult: AccountItem[] | null
	} {
		return {
			search: "",
            incorrectInput: false,
            responseType: "",
            searching: false,
            radioSearch: "company",
            query: "",
            responseStatus: "",
            timestamp: "",
            searchType: "", 
            searchResult: null
			}
	},
    computed:{

    },
	methods: {

        clearSearch(): void {
			this.search = ""
		},

        async findPersonAccount(query: FindPersonAccountRequest): Promise<void>{
            
            try {
                this.searching = true;
                await this.$vStore.dispatch("context/findPersonAccount", query)
                this.searchResult = this.$store.state.context.findPersonAccounts;

                let numberOfMatches = 0

            
                if(this.searchResult?.length !== undefined ){
                    numberOfMatches = this.searchResult?.length
                }
                if(numberOfMatches > 0 ){
                    
                    const numberOfMatches = this.searchResult?.length
                    this.responseStatus = "Retrieved " + numberOfMatches + " matches, view and filter the results below."
                    this.responseType = "success"
                }
                if(numberOfMatches === 0){

                    this.responseStatus = "There was no " + this.searchType + " matching: \"" + this.search + "\". Please try another search term, or search in a different column."
                    this.responseType = "info"
                }
                this.searching = false;
                 
			}catch(e){
                
                this.responseStatus = "Something went wrong. Error message:  " + e
                this.responseType = "error"
                this.searching = false;
            }

        },
		searchInDb(): void {
            if(this.search.length >= 3){

                this.incorrectInput = false
                this.timestamp = format(new Date(),"EEEE dd/MM yyyy '-' HH:mm")
                
                let query: FindPersonAccountRequest
                if (this.radioSearch === RadioSearchValues.company){
                    this.searchType = "companies"

                    query = { userName: null, emailAddress: null, organizationName: this.search}

                }
                else if (this.radioSearch === RadioSearchValues.userId){
                    this.searchType = "user-Id's"

                    query = { userName: this.search, emailAddress: null, organizationName: null}

                }else{
                    this.searchType = "e-mails"

                    query = { userName: null, emailAddress: this.search, organizationName: null}
                }

                this.findPersonAccount(query)
       
            }else{
                this.incorrectInput = true
            }
            
		},
        

	},
})
